import { useCallback } from "react";
import { useFetchProofreadingApi } from "@src/hooks/use-fetch-proofreading-api";
import { useAlertStore } from "@src/store/alert-store";
import { useEditorSettingStore } from "@src/store/editor-setting-store";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { useUserStore } from "@src/store/user-store";
import { buildEditor } from "@src/taskpane/proofreading/build-editor";
import { getEditorText } from "@src/taskpane/proofreading/get-editor-text";
import { ProofreadingStatus } from "@src/types/proofreading-status";
import { buildErrorPanelId } from "@src/utils/build-error-panel-id";
import { buildInputTextStates } from "@src/utils/build-Input-text-states";

export function useProofread() {
  const showAlert = useAlertStore((state) => state.showAlert);

  const setFilteredErrors = useProofreadingStore((state) => state.setFilteredErrors);
  const ignoredErrors = useProofreadingStore((state) => state.ignoredErrors);
  const setStatus = useProofreadingStore((state) => state.setStatus);
  const maxTextLength = useProofreadingStore((state) => state.maxTextLength);
  const setInputTextStates = useProofreadingStore((state) => state.setInputTextStates);
  const commentedErrors = useProofreadingStore((state) => state.commentedErrors);

  const precision = useEditorSettingStore((state) => state.precision);
  const periodAdditionMinLength = useEditorSettingStore((state) => state.periodAdditionMinLength);

  const selectedDictId = useUserStore((state) => state.selectedDictId);

  const { fetchProofreadingApi } = useFetchProofreadingApi();

  const proofread = useCallback(async (): Promise<void> => {
    try {
      const text = await getEditorText();
      if (text.length <= 0) {
        showAlert({
          alertTitle: "エラー",
          alertBody: `文章を入力してください`,
        });
        setStatus(ProofreadingStatus.COMPLETE);
        return;
      }
      if (text.length > maxTextLength) {
        showAlert({
          alertTitle: "文字数制限エラー",
          alertBody: `${maxTextLength.toLocaleString()}文字制限を超えたため、テキスト量を調整してください。`,
        });
        return;
      }

      setStatus(ProofreadingStatus.LOADING);
      setFilteredErrors([]);

      const inputTextStates = await buildInputTextStates([]);

      const response = await fetchProofreadingApi(text, selectedDictId, true);
      if (response.error) {
        showAlert({
          alertTitle: "アプリケーションエラー",
          alertBody: response.message,
          isLogout: response.error === "Not Found Token",
        });
        setStatus(ProofreadingStatus.COMPLETE);
        return;
      }

      const { ok, errors: newErrors } = await buildEditor({
        response,
        precision,
        ignoredErrors: [...ignoredErrors, ...commentedErrors],
        periodAdditionMinLength,
        inputTextStates,
      });

      if (!ok) {
        showAlert({
          alertTitle: "アプリケーションエラー",
          alertBody: "APIでエラーが生じたため、再度校正ボタンを押してください",
        });
        setStatus(ProofreadingStatus.COMPLETE);
        return;
      }

      const afterProofreadingInputTextStates = buildErrorPanelId(inputTextStates, newErrors);

      setFilteredErrors(newErrors);
      setInputTextStates(afterProofreadingInputTextStates);

      setStatus(ProofreadingStatus.COMPLETE);

      return;
    } catch (e) {
      console.error(e);
      setStatus(ProofreadingStatus.COMPLETE);
      return;
    }
  }, [
    commentedErrors,
    fetchProofreadingApi,
    ignoredErrors,
    maxTextLength,
    periodAdditionMinLength,
    precision,
    selectedDictId,
    setFilteredErrors,
    setInputTextStates,
    setStatus,
    showAlert,
  ]);
  return { proofread };
}
