import React from "react";
import { Field, Label, Switch } from "@headlessui/react";
import { useEditorSettingStore } from "@src/store/editor-setting-store";
import clsx from "clsx";

export function AutoProofreadingSettingsToggleButton() {
  const isAutoProofreadingEnabled = useEditorSettingStore((state) => state.isAutoProofreadingEnabled);
  const toggleIsAutoProofreadingEnabled = useEditorSettingStore((state) => state.toggleIsAutoProofreadingEnabled);

  return (
    <Field>
      <div className="flex items-center gap-2 pb-5">
        <Label className="group relative cursor-pointer">自動校正</Label>
        <Switch
          checked={isAutoProofreadingEnabled}
          onChange={toggleIsAutoProofreadingEnabled}
          className={clsx(
            "relative inline-flex h-5 min-w-9 items-center rounded-full transition-colors",
            isAutoProofreadingEnabled ? "bg-neutral-900" : "bg-neutral-200"
          )}
        >
          <span
            className={clsx(
              "inline-block size-3.5 rounded-full bg-white transition-transform",
              isAutoProofreadingEnabled ? "translate-x-[18px]" : "translate-x-[3px]"
            )}
          />
        </Switch>
      </div>
    </Field>
  );
}
