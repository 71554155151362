import { existsIgnoredErrors } from "@src/utils/exists-ignored-errors";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import type {
  CommentedTypolessError,
  AsahiRuleError,
  IgnoredTypolessError,
  TypolessError,
} from "../types/editor-response";
import { ErrorPanelType } from "../types/error-panel-type";
import { ProofreadingStatus } from "../types/proofreading-status";

export type InputTextStates = {
  index: number;
  char: string;
  errorPanelIds: string[];
  isProofreading: boolean;
};

type ProofreadingStore = {
  status: ProofreadingStatus;
  selectedError: TypolessError | undefined;
  errorPanelTab: number;

  errors: TypolessError[];
  revisedErrors: TypolessError[];
  ignoredErrors: IgnoredTypolessError[];
  commentedErrors: CommentedTypolessError[];

  setStatus: (status: ProofreadingStatus) => void;
  setSelectedError: (error: TypolessError | undefined) => void;

  setErrors: (errors: TypolessError[]) => void;
  setFilteredErrors: (errors: TypolessError[]) => void;
  reviseError: (targetError: TypolessError) => void;
  ignoreError: (targetError: TypolessError, isPermanently: boolean) => void;
  revertErrorTemporary: (targetError: TypolessError) => void;
  revertErrorPermanently: (targetError: AsahiRuleError) => void;

  commentError: (targetError: TypolessError, commentId: string) => void;
  deleteCommentedError: (targetError: CommentedTypolessError) => void;

  deleteUnfixedErrorPanel: (id: string) => void;
  clearErrors: () => void;
  setErrorPanelTab: (type: number) => void;

  // 永続無視/永続無視解除のダイアログを非表示とするかどうか
  // デフォルトはfalseで、ダイアログを表示する
  // 「今後このメッセージを表示しない」をチェックして永続無視を実行した場合、以降はtrueとなってダイアログを非表示にする
  hiddenDialogAddPermanentlyIgnoreError: boolean;
  hiddenDialogRevertPermanentlyIgnoreError: boolean;

  setHiddenDialogAddPermanentlyIgnoreError: (hidden: boolean) => void;
  setHiddenDialogRevertPermanentlyIgnoreError: (hidden: boolean) => void;

  maxTextLength: number;
  setMaxTextLength: (maxTextLength: number) => void;

  inputTextStates: InputTextStates[];
  setInputTextStates: (inputTextStates: InputTextStates[]) => void;
};

const initialState = {
  status: ProofreadingStatus.INIT,
  errorPanelTab: ErrorPanelType.UNFIXED,
  selectedError: undefined,
  errors: [],
  revisedErrors: [],
  ignoredErrors: [],
  commentedErrors: [],

  hiddenDialogAddPermanentlyIgnoreError: false,
  hiddenDialogRevertPermanentlyIgnoreError: false,

  maxTextLength: 0,

  inputTextStates: [],
};

export const useProofreadingStore = create<ProofreadingStore>()(
  persist(
    (set) => ({
      ...initialState,
      setStatus: (status) => set(() => ({ status })),
      setSelectedError: (error: TypolessError) =>
        set(() => ({
          selectedError: error,
        })),
      setErrors: (errors) => set(() => ({ errors })),
      setFilteredErrors: (errors) =>
        set(({ ignoredErrors }) => ({
          errors: errors.filter((error) => {
            switch (error.type) {
              case "customRule":
                return !existsIgnoredErrors(
                  {
                    type: "customRule",
                    errorText: error.errorText,
                    candidate: error.candidate,
                  },
                  ignoredErrors
                );
              case "asahiRule":
                return !existsIgnoredErrors(
                  {
                    type: "asahiRule",
                    errorText: error.errorText,
                    candidate: error.candidate,
                  },
                  ignoredErrors
                );
              case "textlint":
                return !existsIgnoredErrors(
                  {
                    type: "textlint",
                    candidate: error.candidate,
                    category: error.category,
                  },
                  ignoredErrors
                );
              case "flamingRisk":
                return !existsIgnoredErrors(
                  {
                    type: "flamingRisk",
                    errorText: error.errorText,
                  },
                  ignoredErrors
                );
              case "tye":
                return !existsIgnoredErrors(
                  {
                    type: "tye",
                    label: error.label,
                    chunk: error.chunk,
                    candidate: error.candidate,
                  },
                  ignoredErrors
                );
            }
          }),
        })),
      reviseError: (targetError) =>
        set(({ revisedErrors, errors }) => ({
          revisedErrors: [...revisedErrors, targetError],
          errors: errors.filter(({ id }) => id !== targetError.id),
        })),
      ignoreError: (targetError, isPermanently: boolean) =>
        set(({ ignoredErrors, errors, inputTextStates }) => ({
          errors: errors.filter((error) => {
            if (targetError.type === "asahiRule" && targetError.ruleId) {
              return (error as AsahiRuleError).ruleId !== targetError.ruleId;
            } else {
              return error.id !== targetError.id;
            }
          }),
          ignoredErrors: [...ignoredErrors, { ...targetError, isPermanently }],
          inputTextStates: inputTextStates.map((status) => {
            const errorPanelIds = status.errorPanelIds;
            if (targetError.type === "asahiRule") {
              const asahiRuleError = errors.find(
                (error) =>
                  errorPanelIds.includes(error.id) &&
                  error.type === "asahiRule" &&
                  (error as AsahiRuleError).ruleId === targetError.ruleId
              );
              if (asahiRuleError) {
                const newErrorPanelIds = errorPanelIds.filter((id) => id !== asahiRuleError.id);
                return { ...status, errorPanelIds: newErrorPanelIds };
              }
              return status;
            }

            if (errorPanelIds.includes(targetError.id)) {
              const newErrorPanelIds = errorPanelIds.filter((errorPanelId) => {
                return errorPanelId !== targetError.id;
              });
              return { ...status, errorPanelIds: newErrorPanelIds };
            }
            return status;
          }),
        })),
      revertErrorPermanently: (targetError) =>
        set(({ ignoredErrors }) => ({
          ignoredErrors: ignoredErrors.filter((ignoredError) => {
            if (ignoredError.type === "asahiRule" && ignoredError.ruleId === targetError.ruleId) {
              return false;
            }
            return true;
          }),
        })),
      revertErrorTemporary: (targetError) =>
        set(({ ignoredErrors }) => ({
          ignoredErrors: ignoredErrors.filter(({ id }) => id !== targetError.id),
        })),

      commentError: (targetError, commentId) =>
        set(({ commentedErrors, errors }) => ({
          errors: errors.filter((error) => {
            return error.id !== targetError.id;
          }),
          commentedErrors: [...commentedErrors, { ...targetError, commentId }],
        })),

      deleteCommentedError: (targetError) =>
        set(({ commentedErrors, inputTextStates }) => ({
          commentedErrors: commentedErrors.filter(({ id }) => id !== targetError.id),
          inputTextStates: inputTextStates.map((status) => {
            const errorPanelIds = status.errorPanelIds;
            if (errorPanelIds.includes(targetError.id)) {
              const newErrorPanelIds = errorPanelIds.filter((errorPanelId) => {
                return errorPanelId !== targetError.id;
              });
              return { ...status, errorPanelIds: newErrorPanelIds };
            }
            return status;
          }),
        })),

      deleteUnfixedErrorPanel: (targetId: string) =>
        set((state) => ({ errors: state.errors.filter(({ id }) => id !== targetId) })),
      clearErrors: () =>
        set(() => ({
          errors: [],
        })),
      setErrorPanelTab: (errorPanelTab) => set(() => ({ errorPanelTab })),
      setHiddenDialogAddPermanentlyIgnoreError: (hidden) =>
        set(() => ({ hiddenDialogAddPermanentlyIgnoreError: hidden })),
      setHiddenDialogRevertPermanentlyIgnoreError: (hidden) =>
        set(() => ({ hiddenDialogRevertPermanentlyIgnoreError: hidden })),
      setMaxTextLength: (maxTextLength) => set(() => ({ maxTextLength })),
      setInputTextStates: (inputTextStates) => set(() => ({ inputTextStates })),
    }),
    {
      name: "proofreading",
      partialize: (state) => ({
        hiddenDialogAddPermanentlyIgnoreError: state.hiddenDialogAddPermanentlyIgnoreError,
        hiddenDialogRevertPermanentlyIgnoreError: state.hiddenDialogRevertPermanentlyIgnoreError,
      }),
    }
  )
);
