import { PROOFREADING_PRECISION_LEVEL } from "@src/constants";
import type { InputTextStates } from "@src/store/proofreading-store";
import { Labels } from "@src/types/editor-response";
import type {
  AsahiRule,
  AsahiRuleError,
  CustomRule,
  CustomRuleError,
  EditorResponse,
  FlamingRisk,
  FlamingRiskError,
  Textlint,
  TextlintError,
  TyeError,
  TypolessError,
} from "@src/types/editor-response";
import { existsIgnoredErrors } from "@src/utils/exists-ignored-errors";
import { getOrderNo } from "@src/utils/get-order-no";
import { buildDisplayText } from "./build-display-text";

export async function buildEditor({
  response,
  precision,
  ignoredErrors,
  periodAdditionMinLength,
  inputTextStates,
}: {
  response: EditorResponse;
  precision: number;
  ignoredErrors: TypolessError[];
  periodAdditionMinLength: number;
  inputTextStates: InputTextStates[];
}): Promise<{ ok: boolean; errors: TypolessError[] }> {
  const isProofreadingRange = ({ score, label, precision }: { score: number; label: string; precision: number }) => {
    return score >= PROOFREADING_PRECISION_LEVEL[label][precision];
  };

  const buildCustomRule = async (errors: CustomRule[], current: number, previousText: string) => {
    const rule = errors.find((error: CustomRule) => {
      return current === error.range[0];
    });
    if (!rule) {
      return null;
    }
    if (
      existsIgnoredErrors(
        {
          type: "customRule",
          errorText: rule.error_text,
          candidate: rule.cand,
        },
        ignoredErrors
      )
    ) {
      return null;
    }

    if (!(await canReplaceText({ errorText: rule.error_text, previousText }))) {
      return null;
    }

    const message = (() => {
      if (rule.message) {
        return rule.message;
      }
      if (rule.is_fuzzy_match) {
        return `もしかして「${rule.cand}」`;
      }
      return "";
    })();

    const error: CustomRuleError = {
      type: "customRule",
      id: getErrorId(),
      indexes: [...Array(rule.error_text.length)].map((_, i) => inputTextStates[current].index + i),
      candidate: rule.cand,
      errorText: rule.error_text,
      message,
      isFuzzyMatch: rule.is_fuzzy_match,
    };

    return error;
  };

  const buildAsahiRule = async (errors: AsahiRule[], current: number, previousText: string) => {
    const rule = errors.find((error) => {
      return current === error.range[0];
    });
    if (!rule) {
      return null;
    }

    // FIXME: 朝日ルールAPIが置換候補を配列で返すようになったため、暫定対応で配列の1つ目を候補とする
    const candidate = typeof rule.cand === "string" ? rule.cand : rule.cand[0];

    if (
      existsIgnoredErrors(
        {
          type: "asahiRule",
          errorText: rule.error_text,
          candidate,
        },
        ignoredErrors
      )
    ) {
      return null;
    }

    if (!(await canReplaceText({ errorText: rule.error_text, previousText }))) {
      return null;
    }

    const ruleErrorCategories = rule.error_categories.map(({ en }) => en);
    const error: AsahiRuleError = {
      type: "asahiRule",
      id: getErrorId(),
      indexes: [...Array(rule.error_text.length)].map((_, i) => inputTextStates[current].index + i),
      ruleId: rule.rule_id,
      candidate,
      errorText: rule.error_text,
      message: rule.message,
      categories: ruleErrorCategories,
    };
    return error;
  };

  const buildTextlint = async (errors: Textlint[], current: number, chunk: string, previousText: string) => {
    const rule = errors.find((error) => {
      return current === error.range[0];
    });
    if (!rule) {
      return null;
    }

    if (
      existsIgnoredErrors(
        {
          type: "textlint",
          candidate: rule.cand,
          category: rule.error_category,
        },
        ignoredErrors
      )
    ) {
      return null;
    }

    if (rule.error_category === "NO_MIXED_PERIOD" && chunk.length < periodAdditionMinLength) {
      return null;
    }

    // SENTENCE_LENGTHは対象の文章全体に指摘がされるため、一文中のtextlint以外の指摘が表示されなくなるので、1文字目だけを指摘する
    if (rule.error_category === "SENTENCE_LENGTH") {
      if (!(await canReplaceText({ errorText: rule.error_text[0], previousText }))) {
        return null;
      }

      const error: TextlintError = {
        type: "textlint",
        id: getErrorId(),
        indexes: [inputTextStates[current].index],
        candidate: rule.cand,
        errorText: rule.error_text[0],
        message: rule.message,
        category: rule.error_category,
      };
      return error;
    }

    // 指摘が可能か
    if (!(await canReplaceText({ errorText: rule.error_text, previousText }))) {
      return null;
    }

    const error: TextlintError = {
      type: "textlint",
      id: getErrorId(),
      indexes: [...Array(rule.error_text.length)].map((_, i) => inputTextStates[current].index + i),
      candidate: rule.cand,
      errorText: rule.error_text,
      message: rule.message,
      category: rule.error_category,
    };

    return error;
  };

  const buildFlamingRisk = (errors: FlamingRisk[], current: number) => {
    const rule = errors.find((error) => {
      return current === error.range[0];
    });
    if (!rule) {
      return null;
    }

    if (
      existsIgnoredErrors(
        {
          type: "flamingRisk",
          errorText: rule.error_text,
        },
        ignoredErrors
      )
    ) {
      return null;
    }

    const error: FlamingRiskError = {
      type: "flamingRisk",
      id: getErrorId(),
      indexes: [...Array(rule.error_text.length)].map((_, i) => inputTextStates[current].index + i),
      candidate: "",
      errorText: rule.error_text,
      message:
        "偏見、差別、攻撃的表現が含まれている可能性があります。\n※AIによるチェックのため、誤検知・見落としにご注意ください。",
    };

    return error;
  };

  const res = await Word.run(async () => {
    const errors: TypolessError[] = [];
    const tye = response.tye;
    const customRule = response.customRule;
    const riskRule = response.riskRule;
    const asahiRule = response.asahiRule;
    const textlint = response.textlint;
    const flamingRisk = response.flamingRisk;
    let previousText = "";
    const chunks = tye.chunks;
    for (let index = 0; index < tye.predictions.length; index++) {
      let char = tye.predictions[index].char;
      const label = tye.predictions[index].label;
      const score = tye.predictions[index].score;
      let candidate = tye.predictions[index].candidate;
      const chunk = chunks[tye.predictions[index].chunk_id];
      const errorIndex = [inputTextStates[index].index];

      if (flamingRisk) {
        const result = buildFlamingRisk(flamingRisk.risks, index);
        if (result) {
          errors.push(result);
        }
      }

      // カスタム辞書
      if (customRule) {
        const customRuleError = await buildCustomRule(customRule.errors, index, previousText);
        if (customRuleError) {
          errors.push(customRuleError);
          previousText += customRuleError.errorText;
          index = index + customRuleError.errorText.length - 1;
          continue;
        }
      }

      if (textlint) {
        const textlintError = await buildTextlint(textlint.errors, index, chunk, previousText);
        if (textlintError) {
          errors.push(textlintError);
          previousText += textlintError.errorText;
          index = index + textlintError.errorText.length - 1;
          continue;
        }
      }

      // Tye
      if (label !== Labels.NO_ERROR_LABEL && isProofreadingRange({ score, label, precision })) {
        // 次の文字も同じラベルか判定する
        if (tye.predictions[index + 1]?.label === label) {
          for (let nextIndex = index + 1; nextIndex < tye.predictions.length; nextIndex++) {
            if (tye.predictions[nextIndex].label !== label) {
              break;
            }
            if (
              !isProofreadingRange({
                score: tye.predictions[nextIndex].score,
                label: tye.predictions[nextIndex].label,
                precision,
              })
            ) {
              break;
            }
            const nextChar = tye.predictions[nextIndex].char;
            const nextCandidate = tye.predictions[nextIndex].candidate ?? "";
            char += nextChar;
            candidate += nextCandidate;
            index++;
            errorIndex.push(inputTextStates[index].index);
          }
        }

        if (
          !existsIgnoredErrors(
            {
              type: "tye",
              label,
              chunk,
              candidate,
            },
            ignoredErrors
          ) &&
          !isSuppressedTyeError({
            label,
            candidate,
            errorText: char,
            chunk,
            periodAdditionMinLength,
          })
        ) {
          errors.push({
            type: "tye",
            id: getErrorId(),
            indexes: errorIndex,
            label,
            candidate,
            texts: buildDisplayText(tye.predictions, index, label, candidate, precision),
            chunk,
            errorText: char,
            message: "",
          });

          previousText += char;
          continue;
        }
      }

      // リスク辞書
      if (riskRule) {
        const riskRuleError = await buildAsahiRule(riskRule.errors, index, previousText);
        if (riskRuleError) {
          errors.push(riskRuleError);
          previousText += riskRuleError.errorText;
          index = index + riskRuleError.errorText.length - 1;
          continue;
        }
      }

      // 朝日辞書
      if (asahiRule) {
        const asahiRuleError = await buildAsahiRule(asahiRule.errors, index, previousText);
        if (asahiRuleError && !isSuppressedAsahiRuleError(asahiRuleError)) {
          errors.push(asahiRuleError);
          previousText += asahiRuleError.errorText;
          index = index + asahiRuleError.errorText.length - 1;
          continue;
        }
      }

      previousText += char;
    }

    return { ok: true, errors };
  }).catch((error) => {
    console.error(error);
    return { ok: false, errors: [] };
  });
  return res;
}

/**
 * TyEのエラーについて、抑止対象の条件かどうか判定する
 */
function isSuppressedTyeError({
  label,
  candidate,
  errorText,
  chunk,
  periodAdditionMinLength,
}: Partial<TyeError> & {
  periodAdditionMinLength: number;
}): boolean {
  // REPLACEで置換先の文字が"。"の場合
  if (label === "REPLACE" && candidate === "。") {
    return true;
  }
  // FIRSTADDで置換先の文字が"A"の場合
  if (label === "FIRSTADD" && candidate === "A") {
    return true;
  }
  // REPLACE or DELETEでエラー文字が"!?！？"の場合
  if ((label === "REPLACE" || label === "DELETE") && errorText && ["!", "?", "！", "？"].includes(errorText)) {
    return true;
  }
  // ADDでエラー文字が記号系、候補が"。"の場合
  if (
    label === "ADD" &&
    errorText &&
    ["!", "?", "！", "？", ")", "）", "」", "』", "]"].includes(errorText) &&
    candidate === "。"
  ) {
    return true;
  }
  // 修正候補が"。"のとき
  if (candidate === "。") {
    // chunkの長さが40文字以下ものは見出しだと判断して、エラーに出さない
    if (chunk && chunk.length < periodAdditionMinLength) {
      return true;
    }
  }
  // 曜日に対する指摘はTyEでは出さない
  if (
    chunk &&
    includeDayOfWeekExpression(chunk) &&
    errorText &&
    "月火水木金土日".includes(errorText) &&
    candidate &&
    "月火水木金土日".includes(candidate)
  ) {
    return true;
  }

  return false;
}

function includeDayOfWeekExpression(input: string): boolean {
  const regex = /[（(][月火水木金土日][）)]/g;
  return regex.test(input);
}

/**
 * ルールのエラーについて、抑止対象の条件かどうか判定する
 */
function isSuppressedAsahiRuleError(ruleError: AsahiRuleError): boolean {
  // FIXEME: ら抜きのルール指摘の検知が多いため、暫定対応
  // ex) 「~される」が全て「~さられる」に修正すべきと指摘される
  if (/(ら抜き|「ら」抜き)/.test(ruleError.message)) {
    return true;
  }
  // FIXEME: candidateが「のための or のために」の誤検知が多いので暫定対応
  if (["のための", "のために"].includes(ruleError.candidate)) {
    return true;
  }

  return false;
}

async function canReplaceText({
  errorText,
  previousText,
}: {
  errorText: string;
  previousText: string;
}): Promise<boolean> {
  return await Word.run(async (context) => {
    // 全てのエラーテキストを調べると処理が遅くなってしまうため
    // 検索できない可能性があるエラーテキストのみ判定する
    const targetErrorTexts = ["\t\t"];
    if (!targetErrorTexts.includes(errorText)) {
      return true;
    }
    const results = context.document.body.search(errorText, {
      matchCase: true,
    });
    results.load("items");
    await context.sync();

    const orderNo = getOrderNo({ text: errorText, previousText });
    return !!results.items[orderNo];
  }).catch((error) => {
    console.error(error);
    return false;
  });
}

function getErrorId() {
  return self.crypto.randomUUID();
}
