import type { InputTextStates } from "@src/store/proofreading-store";
import type { CommentedTypolessError } from "@src/types/editor-response";
import { getOrderNo } from "@src/utils/get-order-no";
import { getPreviousText } from "@src/utils/get-previous-text";

export async function deleteComment({
  targetError,
  inputTextStates,
}: {
  targetError: CommentedTypolessError;
  inputTextStates: InputTextStates[];
}): Promise<{ ok: boolean }> {
  try {
    return await Word.run(async (context) => {
      const results = context.document.body.search(targetError.errorText, {
        matchCase: true,
      });
      results.load("items");
      await context.sync();

      const orderNo = getOrderNo({
        text: targetError.errorText,
        previousText: getPreviousText(inputTextStates, targetError.id),
      });

      const comments: Word.CommentCollection = results.items[orderNo].getComments();
      comments.load("items");
      await context.sync();

      const targetComment = comments.items.find((item) => item.id === targetError.commentId);

      if (targetComment) {
        targetComment.delete();
        await context.sync();
      }

      return { ok: true };
    });
  } catch (error) {
    console.error(error);
    return { ok: false };
  }
}
