import type { InputTextStates } from "@src/store/proofreading-store";
import type { TypolessError } from "@src/types/editor-response";

/**
 *
 * inputTextStatesにエラーパネルのIDを追加する
 *
 * @param {InputTextStates[]} inputTextStates
 * @param {TypolessError[]} errors
 * @return {InputTextStates[]}
 */
export function buildErrorPanelId(inputTextStates: InputTextStates[], errors: TypolessError[]): InputTextStates[] {
  return inputTextStates.map((state) => {
    const filteredNewErrors = errors.filter((error) => error.indexes.includes(state.index));
    const errorIds = filteredNewErrors.map((error) => error.id);
    return { ...state, errorPanelIds: errorIds };
  });
}
