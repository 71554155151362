import React from "react";
import { useProofread } from "@src/hooks/use-proofread";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { ProofreadingStatus } from "@src/types/proofreading-status";
import { clsx } from "clsx";

export function ProofreadingButton() {
  const status = useProofreadingStore((state) => state.status);

  const { proofread } = useProofread();

  const Loading = () => {
    return (
      <div className="flex justify-center" aria-label="読み込み中">
        <div className="size-5 animate-spin rounded-full border-2 border-primary-500 border-t-transparent" />
      </div>
    );
  };

  return (
    <button
      className={clsx(
        "w-14 h-8 rounded border text-center text-sm font-bold",
        status !== ProofreadingStatus.LOADING &&
          "text-white border-primary-600 bg-primary-600 hover:bg-neutral-100 hover:border-neutral-100 hover:text-neutral-900"
      )}
      onClick={proofread}
      disabled={status === ProofreadingStatus.LOADING}
    >
      {status === ProofreadingStatus.LOADING ? <Loading /> : "校正"}
    </button>
  );
}
