import React, { useState } from "react";
import clsx from "clsx";

export function DescriptionTooltip({
  children,
  text,
  forceHidden,
}: Readonly<{
  children: React.ReactNode;
  text: string;
  forceHidden?: boolean;
}>) {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };
  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  return (
    <div className="relative flex items-center">
      <div
        className={clsx(
          "absolute -top-full left-1/2 z-50 mx-auto mt-2 flex -translate-x-1/2 transform items-center whitespace-nowrap rounded bg-neutral-900 px-2 text-2xs text-white transition-all duration-150 pointer-events-none",
          "before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-neutral-900",
          isVisible && "visible opacity-100",
          (!isVisible || forceHidden) && "invisible opacity-0"
        )}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {text}
      </div>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
      </div>
    </div>
  );
}
