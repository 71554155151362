import React from "react";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { deleteComment } from "@src/taskpane/error-panels/delete-comment-button/delete-comment";
import type { CommentedTypolessError } from "@src/types/editor-response";
import { buildInputTextStates } from "@src/utils/build-Input-text-states";
import { LiaCommentSlashSolid } from "react-icons/lia";

export function DeleteCommentButton({ error }: { error: CommentedTypolessError }) {
  const deleteCommentedError = useProofreadingStore((state) => state.deleteCommentedError);
  const inputTextStates = useProofreadingStore((state) => state.inputTextStates);

  const onClick = async (e: { stopPropagation: () => void }) => {
    e.stopPropagation();

    // エラーパネルのコメント済みを削除する
    deleteCommentedError(error);

    // 文書中のコメントを削除する
    const newInputTextStates = await buildInputTextStates(inputTextStates);
    await deleteComment({ targetError: error, inputTextStates: newInputTextStates });
  };

  return <LiaCommentSlashSolid className="h-6 w-6 text-[#BDBDBD]" onClick={onClick} />;
}
