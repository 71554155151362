import React from "react";
import { useAlertStore } from "@src/store/alert-store";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { useVisibleErrors } from "@src/store/use-visible-errors";
import { DescriptionTooltip } from "@src/taskpane/components/tooltip/description-tooltip";
import { focusNextErrorPanel } from "@src/taskpane/error-panels/error-panel/focus-next-error-panel";
import { insertComment } from "@src/taskpane/error-panels/insert-comment-button/insert-comment";
import type { TypolessError } from "@src/types/editor-response";
import { buildInputTextStates } from "@src/utils/build-Input-text-states";
import { LiaCommentAlt } from "react-icons/lia";

export function InsertCommentButton({
  error,
  isProcessing,
  setIsProcessing,
}: {
  error: TypolessError;
  isProcessing: boolean;
  setIsProcessing: (b: boolean) => void;
}) {
  const inputTextStates = useProofreadingStore((state) => state.inputTextStates);
  const commentError = useProofreadingStore((state) => state.commentError);
  const setSelectedError = useProofreadingStore((state) => state.setSelectedError);

  const showAlert = useAlertStore((state) => state.showAlert);

  const { visibleErrors } = useVisibleErrors();

  const onClick = async (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setIsProcessing(true);

    const newInputTextStates = await buildInputTextStates(inputTextStates);
    const res = await insertComment({ targetError: error, inputTextStates: newInputTextStates });
    if (!res.ok) {
      showAlert({
        alertTitle: "アプリケーションエラー",
        alertBody: "エラーが発生しました",
      });
      return;
    }

    const commentId = res.commentId;
    commentError(error, commentId);

    await focusNextErrorPanel({
      visibleErrors,
      id: error.id,
      setSelectedError,
      inputTextStates: newInputTextStates,
    });
    setIsProcessing(false);
  };

  return (
    <DescriptionTooltip text="コメントを追加する">
      <button className="flex text-[#BDBDBD]" onClick={onClick} disabled={isProcessing}>
        <LiaCommentAlt size={24} />
      </button>
    </DescriptionTooltip>
  );
}
