import { useCallback, useState } from "react";
import { useFetchProofreadingApi } from "@src/hooks/use-fetch-proofreading-api";
import { DialogType, useAlertStore } from "@src/store/alert-store";
import { useEditorSettingStore } from "@src/store/editor-setting-store";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { useUserStore } from "@src/store/user-store";
import { focusErrorPanel } from "@src/taskpane/error-panels/error-panel/focus-error-panel";
import { buildEditor } from "@src/taskpane/proofreading/build-editor";
import { getEditorText } from "@src/taskpane/proofreading/get-editor-text";
import { ProofreadingStatus } from "@src/types/proofreading-status";
import { buildErrorPanelId } from "@src/utils/build-error-panel-id";
import { buildInputTextStates } from "@src/utils/build-Input-text-states";
import { getPreviousText } from "@src/utils/get-previous-text";

export function useAutoProofread() {
  const [isProofreading, setIsProofreading] = useState(true);
  const [previousProofreadingText, setPreviousProofreadingText] = useState("");

  const isShowingAlert = useAlertStore((state) => state.isShowingAlert);
  const showAlert = useAlertStore((state) => state.showAlert);

  const setFilteredErrors = useProofreadingStore((state) => state.setFilteredErrors);
  const status = useProofreadingStore((state) => state.status);
  const errors = useProofreadingStore((state) => state.errors);
  const ignoredErrors = useProofreadingStore((state) => state.ignoredErrors);
  const setStatus = useProofreadingStore((state) => state.setStatus);
  const maxTextLength = useProofreadingStore((state) => state.maxTextLength);
  const inputTextStates = useProofreadingStore((state) => state.inputTextStates);
  const setInputTextStates = useProofreadingStore((state) => state.setInputTextStates);
  const setSelectedError = useProofreadingStore((state) => state.setSelectedError);
  const commentedErrors = useProofreadingStore((state) => state.commentedErrors);

  const precision = useEditorSettingStore((state) => state.precision);
  const periodAdditionMinLength = useEditorSettingStore((state) => state.periodAdditionMinLength);

  const selectedDictId = useUserStore((state) => state.selectedDictId);

  const { fetchProofreadingApi } = useFetchProofreadingApi();

  const autoProofread = useCallback(
    async (
      {
        runImmediately,
        runFlamingRisk,
        isInitializing,
      }: { runImmediately?: boolean; runFlamingRisk?: boolean; isInitializing?: boolean } = {
        runImmediately: false,
        runFlamingRisk: false,
        isInitializing: false,
      }
    ): Promise<void> => {
      try {
        if (isShowingAlert || status === ProofreadingStatus.LOADING) {
          return;
        }

        const beforeText = await getEditorText();
        let newInputTextStates = await buildInputTextStates(inputTextStates);
        if (runImmediately) {
          newInputTextStates = newInputTextStates.map((state) => {
            return { ...state, isProofreading: true };
          });
        }

        let proofreadingInputTextStates = newInputTextStates.filter(({ isProofreading }) => isProofreading);
        const proofreadingText = proofreadingInputTextStates.map(({ char }) => char).join("");
        if (proofreadingText.length <= 0) {
          return;
        }

        if (!runImmediately) {
          if (proofreadingText !== previousProofreadingText) {
            setIsProofreading(true);
            setPreviousProofreadingText(proofreadingText);
            return;
          }
          if (!isProofreading) {
            return;
          }
        }

        setIsProofreading(false);
        if (proofreadingText.length > maxTextLength) {
          showAlert({
            alertTitle: "文字数制限エラー",
            alertBody: `${maxTextLength.toLocaleString()}文字制限を超えたため、テキスト量を調整してください。`,
          });
          return;
        }

        setStatus(ProofreadingStatus.LOADING);

        const response = await fetchProofreadingApi(proofreadingText, selectedDictId, runFlamingRisk);
        if (response.error) {
          showAlert({
            alertTitle: "アプリケーションエラー",
            alertBody: response.message,
            isLogout: response.error === "Not Found Token",
          });
          setStatus(ProofreadingStatus.COMPLETE);
          return;
        }

        const { ok, errors: newErrors } = await buildEditor({
          response,
          precision,
          ignoredErrors: [...ignoredErrors, ...commentedErrors],
          periodAdditionMinLength,
          inputTextStates: proofreadingInputTextStates,
        });

        if (!ok) {
          showAlert({
            alertTitle: "アプリケーションエラー",
            alertBody: "APIでエラーが生じたため、再度校正ボタンを押してください",
          });
          setStatus(ProofreadingStatus.COMPLETE);
          return;
        }

        // 新しく生成されたエラーパネルを校正用のInputTextStatesに反映する
        proofreadingInputTextStates = buildErrorPanelId(proofreadingInputTextStates, newErrors);
        const proofreadingLookup = proofreadingInputTextStates.reduce((acc, state) => {
          acc[state.index] = state;
          return acc;
        }, {});

        newInputTextStates = newInputTextStates.map((state) => ({
          ...(proofreadingLookup[state.index] || state),
          isProofreading: false,
        }));

        // 旧エラーパネルと新エラーパネルをマージしてソートする
        const allErrors = [...(runImmediately ? [] : errors), ...newErrors].filter(({ id }) => {
          return newInputTextStates.some(({ errorPanelIds }) => errorPanelIds.includes(id));
        });
        const newErrorPanelIds = Array.from(
          new Set(newInputTextStates.flatMap(({ errorPanelIds }) => errorPanelIds))
        ).filter(Boolean);
        const sortedErrors = allErrors.sort((a, b) => {
          return newErrorPanelIds.indexOf(a.id) - newErrorPanelIds.indexOf(b.id);
        });

        const afterText = await getEditorText();
        if (beforeText !== afterText) {
          setStatus(ProofreadingStatus.COMPLETE);
          return;
        }

        setFilteredErrors(sortedErrors);
        setInputTextStates(newInputTextStates);

        // 校正後に選択しているエラーパネルが存在したらフォーカスする
        const selectedTargetError = errors.find(({ id }) => {
          const errorPanel = document.getElementById(`error-panel-id-${id}`);
          if (errorPanel) {
            return id === errorPanel.dataset.value;
          }
          return false;
        });
        if (
          selectedTargetError &&
          inputTextStates.some(({ errorPanelIds }) => errorPanelIds.includes(selectedTargetError.id))
        ) {
          const previousText = getPreviousText(inputTextStates, selectedTargetError.id);
          const filteredErrors = allErrors.find(({ id }) => {
            const _previousText = getPreviousText(newInputTextStates, id);
            return _previousText === previousText;
          });
          if (filteredErrors) {
            await focusErrorPanel({
              targetError: filteredErrors,
              setSelectedError,
              behavior: "auto",
              inputTextStates: newInputTextStates,
            });
          }
        }

        if (!isInitializing && runFlamingRisk) {
          const flamingRiskError = sortedErrors.find(({ type }) => type === "flamingRisk");
          if (flamingRiskError) {
            await focusErrorPanel({
              targetError: flamingRiskError,
              setSelectedError,
              behavior: "auto",
              inputTextStates: newInputTextStates,
            });
          } else {
            showAlert({
              alertTitle: "炎上リスクは検出されませんでした。",
              alertBody: "※AIによるチェックのため、\n誤検知・見落としにご注意ください。",
              dialogType: DialogType.Success,
            });
          }
        }

        setStatus(ProofreadingStatus.COMPLETE);

        return;
      } catch (e) {
        console.error(e);
        setStatus(ProofreadingStatus.COMPLETE);
        return;
      }
    },
    [
      commentedErrors,
      errors,
      fetchProofreadingApi,
      ignoredErrors,
      inputTextStates,
      isProofreading,
      isShowingAlert,
      maxTextLength,
      periodAdditionMinLength,
      precision,
      previousProofreadingText,
      selectedDictId,
      setFilteredErrors,
      setInputTextStates,
      setSelectedError,
      setStatus,
      showAlert,
      status,
    ]
  );
  return { autoProofread };
}
