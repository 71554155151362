export async function getEditorText() {
  return await Word.run(async (context) => {
    const body = context.document.body;
    body.load("text");
    await context.sync();

    // コメント追加時に文書に挿入される注釈文字を除外する
    const commentText = "\u0005";
    const text = body.text.replace(new RegExp(commentText, "g"), "");

    return text;
  });
}
