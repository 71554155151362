import React, { useState } from "react";
import { Disclosure, DisclosureButton, DisclosurePanel, Transition } from "@headlessui/react";
import { useAlertStore } from "@src/store/alert-store";
import { useProofreadingStore } from "@src/store/proofreading-store";
import { DeleteCommentButton } from "@src/taskpane/error-panels/delete-comment-button/delete-comment-button";
import { RevertErrorButton } from "@src/taskpane/error-panels/error-panel/revert-error-button";
import { AsahiRuleIgnoreButton } from "@src/taskpane/error-panels/ignore-button/asahi-rule-ignore-button";
import { InsertCommentButton } from "@src/taskpane/error-panels/insert-comment-button/insert-comment-button";
import { Labels } from "@src/types/editor-response";
import type { TypolessError, IgnoredTypolessError, CommentedTypolessError } from "@src/types/editor-response";
import { ErrorPanelType } from "@src/types/error-panel-type";
import { buildInputTextStates } from "@src/utils/build-Input-text-states";
import { clsx } from "clsx";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IgnoreButton } from "../ignore-button/ignore-button";
import { ReviseButton } from "../revise-button/revise-button";
import { focusErrorPanel } from "./focus-error-panel";
import { getErrorPanelColorStyle } from "./get-error-panel-color-style";
import { LabelName } from "./label-name";
import { OtherRuleText } from "./other-rule-text";
import { TyeErrorText } from "./tye-error-text";

export function ErrorPanel({ error, panelType }: { error: TypolessError; panelType: ErrorPanelType }) {
  const [isProcessing, setIsProcessing] = useState(false);

  const selectedError = useProofreadingStore((state) => state.selectedError);
  const setSelectedError = useProofreadingStore((state) => state.setSelectedError);
  const inputTextStates = useProofreadingStore((state) => state.inputTextStates);

  const showAlert = useAlertStore((state) => state.showAlert);
  const alertBody = (
    <div className="space-y-2">
      <p>該当の校正箇所が見つかりませんでした。次のようなことが考えられます。</p>
      <p>
        ・文書にフィールドコードが含まれていると正しく校正できない場合があります。「Alt+F9」でフィールドコードを非表示にして校正してください。
      </p>
      <p>上記のことを試しても解決しない場合は無視ボタンを押して校正結果を無視してください。</p>
    </div>
  );

  const onClick = async () => {
    if (panelType !== ErrorPanelType.UNFIXED) {
      return;
    }
    const newInputTextStates = await buildInputTextStates(inputTextStates);
    const { ok } = await focusErrorPanel({
      targetError: error,
      setSelectedError,
      behavior: "smooth",
      inputTextStates: newInputTextStates,
    });
    if (!ok) {
      showAlert({
        alertTitle: "アプリケーションエラー",
        alertBody,
      });
    }
  };

  const { borderColor, hoverColor } = getErrorPanelColorStyle({ error });
  return (
    <Disclosure key={error.id} defaultOpen={panelType !== ErrorPanelType.FIXED}>
      {({ open }) => (
        <div
          id={`error-panel-id-${error.id}`}
          className={clsx(
            "p-3 cursor-pointer rounded-lg border-2 shadow-[0_0_9px_0_rgba(0,0,0,0.15)] group/panel relative",
            panelType === ErrorPanelType.UNFIXED && selectedError?.id === error.id && borderColor,
            hoverColor
          )}
          onClick={onClick}
          data-value={selectedError?.id === error.id && error.id}
        >
          <div className="flex justify-between">
            <LabelName open={open} error={error} panelType={panelType} />
            <DisclosureButton>
              {open ? <MdKeyboardArrowDown className="h-6 w-6" /> : <MdKeyboardArrowUp className="h-6 w-6" />}
            </DisclosureButton>
          </div>
          <div className={clsx(isProcessing && "opacity-40")}>
            <Transition
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <DisclosurePanel className="rounded-lg">
                <div className="py-1.5">
                  {error.type === "tye" ? <TyeErrorText error={error} /> : <OtherRuleText error={error} />}
                </div>
                <div className="flex justify-between items-center">
                  {canReplaceText(error) ? <ReviseButton error={error} panelType={panelType} /> : <div />}
                  <div className="flex space-x-2">
                    {panelType === ErrorPanelType.UNFIXED && (
                      <>
                        <InsertCommentButton
                          error={error}
                          isProcessing={isProcessing}
                          setIsProcessing={setIsProcessing}
                        />
                        {error.type === "asahiRule" ? (
                          <AsahiRuleIgnoreButton error={error} />
                        ) : (
                          <IgnoreButton error={error} />
                        )}
                      </>
                    )}

                    {panelType === ErrorPanelType.IGNORED && (
                      <RevertErrorButton error={error as IgnoredTypolessError} />
                    )}
                    {panelType === ErrorPanelType.COMMENTED && (
                      <DeleteCommentButton error={error as CommentedTypolessError} />
                    )}
                  </div>
                </div>
              </DisclosurePanel>
            </Transition>
          </div>

          {isProcessing && (
            <div className="absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2">
              <div className="size-7 animate-spin rounded-full border-2 border-b-neutral-900 border-t-transparent" />
            </div>
          )}
        </div>
      )}
    </Disclosure>
  );
}

const canReplaceText = (error: TypolessError) => {
  // label=DELETEの時は置換ボタンを押下して削除するのでcandidateがNULLでも置換可能とする
  if (error.type === "tye" && error.label === Labels.DELETE_LABEL) {
    return true;
  }

  if (!error.candidate) {
    return false;
  }
  if (error.type === "asahiRule") {
    if (/[、,，|/／《》\s]/.test(error.candidate)) {
      return false;
    }
    if (error.candidate === "置換候補はありません") {
      return false;
    }
  }

  return true;
};
